<template>
  <footer class="bg-white">
    <div class="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
      <div
          class="pt-8 text-center xl:text-left flex flex-col xl:flex-row items-center justify-center xl:justify-between space-y-2 xl:space-y-0">
        <p class="text-xs leading-5 text-gray-700">
          &copy; {{ new Date().getFullYear() }} The Listing App. All rights reserved.
          <br>
          Made with ❤️ by the team.
        </p>

        <div class="flex items-center">
          <nuxt-link class="text-gray-700 hover:text-gray-800" to="/privacy-policy">
            Privacy Policy
          </nuxt-link>

          <span class="mx-3 text-gray-700">|</span>

          <nuxt-link class="text-gray-700 hover:text-gray-800" to="/terms-of-service">
            Terms of Service
          </nuxt-link>

          <span class="mx-3 text-gray-700">|</span>

          <nuxt-link class="text-gray-700 hover:text-gray-800" to="/refund-policy">
            Refund Policy
          </nuxt-link>
        </div>

        <div class="flex">
          <span class="mr-2">
            Follow us on:
          </span>

          <div class="space-x-3">
            <template v-for="platform in platforms">
              <a v-if="platform.follow_us_links.length" :key="platform.id"
                 :href="platform.follow_us_links[0]" class="text-gray-400 hover:text-gray-500" target="_blank">
                <icon :name="platform.icon" class="h-6 w-6"/>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {infoStore} from "~/stores/info.js"

const platforms = computed(() => infoStore().platforms)
</script>
